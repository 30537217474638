import {
  BundleDescriptor,
  BundleContent,
  FileName,
  Metadata,
  SvgContent,
  SymbolName,
  getFileNameForSymbol,
} from '../types';

export class SymbolsFetcher {
  private cache = new Map<SymbolName, SvgContent>();
  baseUrl =
    'https://static.parastorage.com/services/santa-editor-symbols/dist/';
  private fetching = new Map<SymbolName, Promise<void>>();

  private fetchFile<T>(fileName: FileName): Promise<T> {
    return fetch(`${this.baseUrl}svg/${fileName}`).then((res) => res.json());
  }
  public preloadBundle(bundle: BundleDescriptor) {
    const request = this.fetchFile<BundleContent>(bundle.fileName).then(
      (bundle) => {
        for (const [name, content] of bundle.symbols) {
          this.cache.set(name, content);
          this.fetching.delete(name);
        }
      },
    );

    for (const name of bundle.symbols) {
      this.fetching.set(name, request);
    }
  }

  public fetch(
    name: SymbolName,
    meta: Metadata,
    cb: () => void,
  ): SvgContent | undefined {
    if (this.cache.has(name)) {
      return this.cache.get(name);
    }
    if (!this.fetching.has(name)) {
      this.fetching.set(
        name,
        this.fetchFile<SvgContent>(getFileNameForSymbol(name, meta)).then(
          (svgContent) => {
            this.cache.set(name, svgContent);
            this.fetching.delete(name);
          },
        ),
      );
    }
    this.fetching.get(name)!.then(cb);
  }
}

export const symbolsFetcher = new SymbolsFetcher();
