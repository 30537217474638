import React from 'react';
import type { MetadataObject, SymbolName } from '../types';
import { symbolsFetcher } from './fetcher';

const METADATA: MetadataObject = require('../../gen/metadata.json');

export interface SymbolProps {
  name: string;
  className?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
  style?: React.CSSProperties;
  customDimensions?: { height?: number; width?: number };
}

const cx = (...args: (string | null | undefined)[]) =>
  args.filter(Boolean).join(' ');

export class Symbol extends React.PureComponent<SymbolProps> {
  rerender = () => {
    // const rerender = useReducer((x) => x + 1, 0)[1];
    this.forceUpdate();
  };
  render() {
    const { props } = this;
    const symbolName = props.name as any as SymbolName;

    let content: string | undefined;
    let svgProps: React.SVGProps<SVGSVGElement> | undefined;

    const meta = METADATA[symbolName];
    if (meta) {
      svgProps = meta[1];
      content = symbolsFetcher.fetch(symbolName, meta, this.rerender)?.content;
    } else {
      console.error('SVG symbol %s does not exist', props.name);
    }

    const className = cx(
      svgProps?.className,
      `symbol symbol-${props.name}`,
      props.className,
    );

    return React.createElement('svg', {
      ...svgProps,
      ...props.customDimensions,
      style: {
        ...svgProps?.style,
        ...props.style,
      },
      className,
      onClick: props.onClick,
      //eslint-disable-next-line no-useless-concat
      ['dangerously' + 'SetInnerHTML']: { __html: content ?? '' },
    });
  }
}
