import type React from 'react';

export interface SvgContent {
  content: string;
}

export type FileName = string & {
  __brand: '__fileName';
};

export interface FileHash {
  __brand: '__fileHash';
}

export type SymbolName = string & {
  __brand: '__symbolName';
};

export interface BundleDescriptor {
  fileName: FileName;
  symbols: SymbolName[];
}

export interface BundleContent {
  symbols: Array<[symbol: SymbolName, content: SvgContent]>;
}

export function getFileNameForSymbol(
  symbol: SymbolName,
  meta: Metadata,
): FileName {
  return `${symbol}.${meta[0]}.json` as any as FileName;
}

export type Metadata = Readonly<
  [hash: FileHash, props: React.SVGAttributes<SVGSVGElement> | undefined]
>;

export type MetadataObject = Record<SymbolName, Metadata>;
